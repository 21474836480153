import arrowUp from './icons/icon-arrow-up.svg';
import arrowRight from './icons/icon-arrow-right.svg';
import arrowDown from './icons/icon-arrow-down.svg';
import arrowLeft from './icons/icon-arrow-left.svg';
import arrowheadDown from './icons/icon-arrowhead-down.svg';
import arrowheadUp from './icons/icon-arrowhead-up.svg';
import attachment from './icons/icon-attachment.svg';
import authorise from './icons/icon-authorise.svg';
import bicycle from './icons/icon-bicycle.svg';
import bus from './icons/icon-bus.svg';
import calendar from './icons/icon-calendar.svg';
import car from './icons/icon-car.svg';
import chatHeart from './icons/icon-chat-heart.svg';
import chatQuestion from './icons/icon-chat-question.svg';
import checkFilled from './icons/icon-check-filled.svg';
import check from './icons/icon-check.svg';
import chevronUp from './icons/icon-chevron-up.svg';
import chevronRight from './icons/icon-chevron-right.svg';
import chevronDown from './icons/icon-chevron-down.svg';
import chevronLeft from './icons/icon-chevron-left.svg';
import chevronCircleUp from './icons/icon-chevron-circle-up.svg';
import chevronCircleRight from './icons/icon-chevron-circle-right.svg';
import chevronCircleDown from './icons/icon-chevron-circle-down.svg';
import chevronCircleLeft from './icons/icon-chevron-circle-left.svg';
import close from './icons/icon-close.svg';
import compare from './icons/icon-compare.svg';
import compareRemove from './icons/icon-compare-remove.svg';
import controlPrevious from './icons/icon-control-previous.svg';
import controlPlay from './icons/icon-control-play.svg';
import controlNext from './icons/icon-control-next.svg';
import copy from './icons/icon-copy.svg';
import disabled from './icons/icon-disabled.svg';
import done from './icons/icon-done.svg';
import download from './icons/icon-download.svg';
import edit from './icons/icon-edit.svg';
import errorFilled from './icons/icon-error-filled.svg';
import error from './icons/icon-error.svg';
import expandableMinus from './icons/icon-expandable-minus.svg';
import expandablePlus from './icons/icon-expandable-plus.svg';
import heartFilled from './icons/icon-heart-filled.svg';
import heart from './icons/icon-heart.svg';
import helpFilled from './icons/icon-help-filled.svg';
import help from './icons/icon-help.svg';
import history from './icons/icon-history.svg';
import infoFilled from './icons/icon-info-filled.svg';
import info from './icons/icon-info.svg';
import internet from './icons/icon-internet.svg';
import linkBreadcrumb from './icons/icon-link-breadcrumb.svg';
import linkExternal from './icons/icon-link-external.svg';
import linkList from './icons/icon-link-list.svg';
import login from './icons/icon-login.svg';
import logout from './icons/icon-logout.svg';
import mailSend from './icons/icon-mail-send.svg';
import mapLayers from './icons/icon-map-layers.svg';
import mapLocationFilled from './icons/icon-map-location-filled.svg';
import mapLocation from './icons/icon-map-location.svg';
import mapMyLocation from './icons/icon-map-my-location.svg';
import mapRoute from './icons/icon-map-route.svg';
import map from './icons/icon-map.svg';
import menu from './icons/icon-menu.svg';
import message from './icons/icon-message.svg';
import minus from './icons/icon-minus.svg';
import pedestrian from './icons/icon-pedestrian.svg';
import pin from './icons/icon-pin.svg';
import phone from './icons/icon-phone.svg';
import plus from './icons/icon-plus.svg';
import preview from './icons/icon-preview.svg';
import print from './icons/icon-print.svg';
import radioButtonOn from './icons/icon-radio-button-on.svg';
import refresh from './icons/icon-refresh.svg';
import registers from './icons/icon-registers.svg';
import remove from './icons/icon-remove.svg';
import reply from './icons/icon-reply.svg';
import save from './icons/icon-save.svg';
import search from './icons/icon-search.svg';
import settings from './icons/icon-settings.svg';
import signLanguageContent from './icons/icon-sign-language-content.svg';
import swapRounded from './icons/icon-swap-rounded.svg';
import swapVertical from './icons/icon-swap-vertical.svg';

export type IconKeys = keyof typeof icons;

export const icons = {
  arrowUp,
  arrowRight,
  arrowDown,
  arrowLeft,
  arrowheadDown,
  arrowheadUp,
  attachment,
  authorise,
  bicycle,
  bus,
  calendar,
  car,
  chatHeart,
  chatQuestion,
  checkFilled,
  check,
  chevronUp,
  chevronRight,
  chevronDown,
  chevronLeft,
  chevronCircleUp,
  chevronCircleRight,
  chevronCircleDown,
  chevronCircleLeft,
  close,
  compare,
  compareRemove,
  controlPrevious,
  controlPlay,
  controlNext,
  copy,
  disabled,
  done,
  download,
  edit,
  errorFilled,
  error,
  expandableMinus,
  expandablePlus,
  heartFilled,
  heart,
  helpFilled,
  help,
  history,
  infoFilled,
  info,
  internet,
  linkBreadcrumb,
  linkExternal,
  linkList,
  login,
  logout,
  mailSend,
  mapLayers,
  mapLocationFilled,
  mapLocation,
  mapMyLocation,
  mapRoute,
  map,
  menu,
  message,
  minus,
  pedestrian,
  pin,
  phone,
  plus,
  preview,
  print,
  radioButtonOn,
  refresh,
  registers,
  remove,
  reply,
  save,
  search,
  settings,
  signLanguageContent,
  swapRounded,
  swapVertical
};
